import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { app } from '../firebase';
import { toast } from 'react-toastify';

export const callChangeDisplayName = async (newDisplayName) => {
	try {
		// Check authentication state
		const auth = getAuth(app);
		if (!auth.currentUser) {
			throw new Error('User is not authenticated');
		}
		// console.log('Current user:', auth.currentUser); // Debugging

		// Specify the region (e.g., 'us-central1')
		const functions = getFunctions(app, 'us-central1');
		const changeDisplayNameFunction = httpsCallable(functions, 'changeDisplayName');

		// Call the function
		await changeDisplayNameFunction({
			displayName: newDisplayName,
		});

		toast('Display name updated!', { toastId: 'displayName', type: 'success', theme: 'colored' });
		// console.log('Cloud Function response:', response.data);
	} catch (error) {
		console.error('Error calling Firebase Cloud Function:', error);
	}
};
