import { useNavigate } from 'react-router-dom';
import { Hypnosis } from 'react-cssfx-loading';
import { app, db } from '../firebase';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAgent } from '../context/AgentContext';
import { useUser } from '../context/UserContext';
import { PortalWithState } from 'react-portal';
import SignInModal from '../components/SignInModal';

const CreatorRoomButton = ({ agent, enterRoom, displayName }) => {
	return (
		<div
			onClick={() => enterRoom(agent.agentId)}
			style={{ maxWidth: '544px' }}
			className="m-2 sm:m-4 flex justify-center items-center bg-slate-600 mx-auto cursor-pointer rounded-lg shadow-md hover:bg-slate-700"
		>
			<img alt="" src={agent.portraitURL} width="120px" height="160px" className="rounded-md shadow-md" />
			<div className="m-4 text-white text-left text-xs sm:text-sm">
				<p>
					"Welcome, traveler {displayName}. As The Creator, I offer guidance. Speak with me if you require assistance. Or for the brave at heart engage with my companions, and unveil life's mysteries
					one conversation at a time. 😜"
				</p>
				<p className="text-xl mt-4">Chat with {agent.name}</p>
			</div>
		</div>
	);
};

const RoomButton = ({ agent, enterRoom }) => {
	if (agent.name === 'The Creator') {
		return null; // Fixed: Return null instead of undefined
	}
	return (
		<div onClick={() => enterRoom(agent.agentId)} className="m-2 sm:m-4 flex justify-center items-center bg-slate-600 mx-auto cursor-pointer w-64 rounded-lg shadow-md hover:bg-slate-700">
			<img alt="" src={agent.portraitURL} width={120} height={160} className="rounded-md shadow-md" />
			<div className="m-4 text-xl text-white">
				<p>Chat with {agent.name}</p>
			</div>
		</div>
	);
};

const Lobby = () => {
	const navigate = useNavigate();
	const { user, displayName, uid } = useUser();
	const [agentCreator, setAgentCreator] = useState(undefined);
	const [roomIsLoading, setRoomIsLoading] = useState(false);
	const { agents, agentsLoading, setAgentById } = useAgent();

	useEffect(() => {
		if (agents && !agentsLoading) {
			setAgentCreator(agents.find((obj) => obj.name === 'The Creator'));
		}
	}, [agents, agentsLoading]);

	const enterRoom = async (agentId, openPortal) => {
		if (!user) {
			openPortal();
			return;
		}

		setAgentById(agentId);

		try {
			const agentRef = doc(db, `users/${uid}/agents`, agentId);
			const agentDocSnapshot = await getDoc(agentRef);

			if (!agentDocSnapshot.exists()) {
				setRoomIsLoading(true);
				const functions = getFunctions(app, 'us-central1'); // Specify region

				const createUserAgentData = httpsCallable(functions, 'createUserAgentData');
				await createUserAgentData({ agentId, version: 0 }); // No userId needed
				setRoomIsLoading(false);
			}
			navigate(`/room/${agentId}`);
		} catch (err) {
			console.error('Error:', err);
			setRoomIsLoading(false);
		}
	};

	return (
		<>
			{roomIsLoading && (
				<div style={{ zIndex: '200' }} className="w-screen h-screen bg-black fixed left-0 top-0 flex justify-center items-center bg-opacity-90">
					<div className="opacity-40">
						<Hypnosis style={{ margin: 'auto' }} color="white" width="50px" height="50px" duration="2s" />
						<p className="text-white pt-4 text-xs sm:text-lg text-center">initializing...</p>
					</div>
				</div>
			)}

			{!agentsLoading && agents && (
				<div className="mt-10 sm:mt-0 mb-8">
					<div className="flex justify-center mb-4">
						{agentCreator && (
							<PortalWithState closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<CreatorRoomButton agent={agentCreator} displayName={displayName} enterRoom={() => enterRoom(agentCreator.agentId, openPortal)} />
										{portal(<SignInModal closePortal={closePortal} />)}
									</>
								)}
							</PortalWithState>
						)}
					</div>

					<div className="flex flex-wrap justify-center items-center">
						{agents.map((agent) => (
							<PortalWithState key={agent.agentId} closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<RoomButton enterRoom={() => enterRoom(agent.agentId, openPortal)} agent={agent} />
										{portal(<SignInModal closePortal={closePortal} />)}
									</>
								)}
							</PortalWithState>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default Lobby;
