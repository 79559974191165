import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'; // No need to connect auth emulator
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // Using Production Auth
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

// Connect to Firebase emulators in development (except Auth)
if (process.env.NODE_ENV === 'development') {
	connectFirestoreEmulator(db, 'localhost', 8080); // Firestore Emulator
	connectFunctionsEmulator(functions, 'localhost', 5001); // Functions Emulator
	connectStorageEmulator(storage, 'localhost', 9199); // Storage Emulator
}
